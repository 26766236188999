<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">

        <div class="brand-text text-primary ml-1">
          <b-img
            :src="appLogoImage"
            alt="Login V2"
            style="width:10%"
          />
        </div>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h4"
          >
            Welcome to Approval Diskon Khusus!👋
          </b-card-title>
          <b-card-text class="mb-2" style="font-size:12px">
            Please input NIK to go application.
          </b-card-text>         <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
            >
              <div>
                <b-form-input
                  id="emp_id"
                  v-model="emp_id"
                  placeholder="Input NIK"
                />
                <br>
                <b-form-input
                  id="pass_id"
                  type="password"
                  v-model="pass_id"
                  placeholder="Input Password"
                />
                <br>
              </div>

              <!-- submit buttons -->
              <!-- <div
                v-for="x in BUnit.flat()"
                :key="x.id_bu"
              > -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="login(pass_id, emp_id)"
                >
                Login
                  <!-- {{ x.nama_bu }} -->
                </b-button>
                <br>
              <!-- </div> -->
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>COPYRIGHT © 2022 IT DBC   </span>
          </b-card-text>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BFormInput
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { onMounted } from '@vue/composition-api'
import router from '@/router'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationObserver,
    BFormInput
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: 'admin',
      userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogoImage: require('@/assets/images/logo/logo-dbc.png'),
      BUnit: this.BUData,
      timeout: null,
      // validation rules
      required,
      email,
      emp_id: '',
      pass_id: '',
    }
  },
  computed: {
    // alert(router.currentRoute.params.id_bu)
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    // async login(id_bu) {
    // async login(id_bu, empID) {
    async login(passID, empID) {
      // console.log(id_bu+' --- '+empID);
      // alert(id_bu)
      if(empID == '' || passID == ''){
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Warning!',
            // title: `Welcome ${userData.fullName || userData.username}`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Input Employee ID dan Password!',
          },
        })
      }
      else{
        if(empID == '2000513' && passID == empID+'123'){
          // alert('OS');
          this.$refs.loginForm.validate().then(success => {
            if (success) {
              useJwt.login({
                nik: empID,
                email: this.userEmail,
                password: this.password,
              }).then(async response => {
                  // console.log(response.data.userData);
                  // const results = await axios.post(`${useAppConfig().AppUrl}global/getDataLogin`, {'emp_id': response.data.userData.emp_id})
                  const results = await axios.post(`${useAppConfig().AppUrl}global/getDataLoginManual`, {'emp_id': empID})
                  console.log(results.data);
                  if(results.data.message == "Data user tidak ditemukan!"){
                    this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Warning!',
                            // title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'danger',
                            text: results.data.message,
                          },
                        })
                  }
                  else{
                    const { userData } = response.data
                    // console.log(JSON.stringify(userData));
                    // console.log(JSON.stringify(results.data[0].login[0]));
                    useJwt.setToken(response.data.accessToken)
                    useJwt.setRefreshToken(response.data.refreshToken)
                    // localStorage.setItem('userData', JSON.stringify(userData))
                    // localStorage.setItem('userLogin', JSON.stringify(results.data[0].login[0]))
                    localStorage.setItem('userData', JSON.stringify(results.data[0].login[0]))
                    localStorage.setItem('bisnis_unit', results.data[0].login[0].id_bu)
                    localStorage.setItem('emp_id', results.data[0].login[0].id)
                    localStorage.setItem('exp-ADK', (new Date).getTime()+(1000*60*60*24)) // 1 day //(new Date).setSeconds((new Date).getSeconds()+5)); // 5 detik
                    localStorage.setItem('internal', '1')
                    this.$ability.update(userData.ability)

                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                    // insert data log //
                    const log = await axios.post(`${useAppConfig().AppUrl}global/insertLogAkses`, {
                      'emp_id': results.data[0].login[0].id, 'param': results.data[0].login[0], url: window.location.origin, 'keterangan': 'user', 'status': 'login'
                    })

                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    this.$router.push('apps/home')
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Welcome',
                            // title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'You have successfully logged in.',
                          },
                        })
                      })
                  }                
              })
              .catch(error => {
                console.log(error);
                // this.$refs.loginForm.setErrors(error.response.data.error)
              })
            }
          })
        }
        else if(empID == empID && passID == empID+''+new Date().getFullYear()+'123'){
          this.$refs.loginForm.validate().then(success => {
            if (success) {
              useJwt.login({
                nik: empID,
                email: this.userEmail,
                password: this.password,
              }).then(async response => {
                  // console.log(response.data.userData);
                  // const results = await axios.post(`${useAppConfig().AppUrl}global/getDataLogin`, {'emp_id': response.data.userData.emp_id})
                  const results = await axios.post(`${useAppConfig().AppUrl}global/getDataLoginManual`, {'emp_id': empID})
                  console.log(results.data);
                  if(results.data.message == "Data user tidak ditemukan!"){
                    this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Warning!',
                            // title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'danger',
                            text: results.data.message,
                          },
                        })
                  }
                  else{
                    const { userData } = response.data
                    // console.log(JSON.stringify(userData));
                    // console.log(JSON.stringify(results.data[0].login[0]));
                    useJwt.setToken(response.data.accessToken)
                    useJwt.setRefreshToken(response.data.refreshToken)
                    // localStorage.setItem('userData', JSON.stringify(userData))
                    // localStorage.setItem('userLogin', JSON.stringify(results.data[0].login[0]))
                    localStorage.setItem('userData', JSON.stringify(results.data[0].login[0]))
                    localStorage.setItem('bisnis_unit', results.data[0].login[0].id_bu)
                    localStorage.setItem('emp_id', results.data[0].login[0].id)
                    localStorage.setItem('exp-ADK', (new Date).getTime()+(1000*60*60*24)) // 1 day //(new Date).setSeconds((new Date).getSeconds()+5)); // 5 detik
                    localStorage.setItem('internal', '1')
                    this.$ability.update(userData.ability)

                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                    // insert data log //
                    const log = await axios.post(`${useAppConfig().AppUrl}global/insertLogAkses`, {
                      'emp_id': results.data[0].login[0].id, 'param': results.data[0].login[0], url: window.location.origin, 'keterangan': 'user', 'status': 'login'
                    })

                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    this.$router.push('apps/home')
                      .then(() => {
                        this.$toast({
                          component: ToastificationContent,
                          position: 'top-right',
                          props: {
                            title: 'Welcome',
                            // title: `Welcome ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: 'You have successfully logged in.',
                          },
                        })
                      })
                  }                
              })
              .catch(error => {
                console.log(error);
                // this.$refs.loginForm.setErrors(error.response.data.error)
              })
            }
          })
        }
        else{
          alert('Data user tidak ditemukan!');
        }
      }
    },
    async login_portal(id) {
      // localStorage.clear()
      // let data = JSON.parse(id)
      // console.log(data);
      // alert(id_bu)
      useJwt.login({
        nik: id,
        email: this.userEmail,
        password: this.password,
      })
        .then(async response => {
          console.log(response.data.userData);
          const results = await axios.post(`${useAppConfig().AppUrl}global/getDataLogin`, {'emp_id': id})
          console.log(results.data[0].login[0]);
          const { userData } = response.data
          // console.log(JSON.stringify(userData));
          // console.log(JSON.stringify(results.data[0].login[0]));
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          // localStorage.setItem('userData', JSON.stringify(userData))
          // localStorage.setItem('userLogin', JSON.stringify(results.data[0].login[0]))
          localStorage.setItem('userData', JSON.stringify(results.data[0].login[0]))
          localStorage.setItem('bisnis_unit', results.data[0].login[0].id_bu)
          localStorage.setItem('emp_id', id)
          localStorage.setItem('exp-ADK', (new Date).getTime()+(1000*60*60*24)) // 1 day //(new Date).setSeconds((new Date).getSeconds()+5)); // 5 detik
          this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          this.$router.push('../apps/home')
          // this.$router.push('../apps/approval/edit/'+data.trn_code)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Welcome',
                  // title: `Welcome ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully logged in.',
                },
              })
            })
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    }
    // delay() {
    //   // Re-enable after 5 seconds
    //   this.timeout = setTimeout(() => {
    //     this.myFunction()
    //   }, 100)
    // },
    // myFunction() {
    //   // Your function
    //   if (localStorage.getItem('bisnis-unit') === '') {
    //     this.$router.replace('apps/proses/list')
    //   }
    // },
  },
  mounted() {
    // alert('Cek')
    if(router.currentRoute.params.id != undefined){
      // alert(`AutoLogin : `+window.location.host+` ${useAppConfig().AppUrl}`)
      let id = router.currentRoute.params.id
      // alert(id)
      this.login_portal(id)
    }
  },
  setup() {
    // alert(router.currentRoute.params.id_bu)
    const BUData = []
    onMounted(async () => {
      // const result = await axios.post('http://192.168.254.196:9183/api_bsc/hakAkses/listBu')
      // const dataBU = result.data.data
      const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      // console.log(result.data[0].bu);
      const dataBU = result.data[0].bu
      BUData.push(dataBU)
    })

    // const userDataInfo = ref ({})
    onMounted(async () => {
      // Email.userEmail = 'admin@demo.com'
      // Email.password = 'admin'
      if(router.currentRoute.params.id_bu != undefined){
        // alert(`AutoLogin : `+window.location.host+` ${useAppConfig().AppUrl}`+Email.userEmail)

        let id_bu = router.currentRoute.params.id_bu
        // this.login_auto(id_bu)
      }
      // const result = await axios.post('http://192.168.254.196:9183/api_bsc/hakAkses/listBu')
      // const dataBU = result.data.data
      // const result = await axios.get(`${useAppConfig().AppUrl}global/listCombo`)
      // // console.log(result.data[0].bu);
      // const dataBU = result.data[0].bu
      // // BUData.push(dataBU)
    })

    return {
      BUData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
